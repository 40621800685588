body {
    &.admin-bar {
        .header__sticky {
            top: 32px;

            .nav-main {
                &-content {
                    top: 112px;
                }
            }
        }
    }
}

.header {
    width: 100%;
    height: 720px;
    background-color: $color-dark;
    transition: $duration;

    &__container {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        &::before {
            content: url($urlShort + 'home-icons/backgrounds/forme_header.svg');
            width: 515px;
            height: 500px;
            position: absolute;
            top: -60px;
            left: -380px;
            opacity: .1;
        }

        &::after {
            content: '';
            width: 3000px;
            height: 661px;
            position: absolute;
            left: 399px;
            bottom: 0;
            z-index: 2;
            background: #12223E;
            border-radius: 200px 0 0;
            opacity: 0;
            transition: $duration;
        }

        &.js-active-submenu {
            &::after {
                opacity: 1;
                z-index: 991;
            }
        }
    }

    // Menu when scrolling on the page
    &__sticky {
        width: 100%;
        height: 80px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 995;
        opacity: 0;
        visibility: hidden;
        transition: $duration;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-dark;
        }

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $color-light;
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }

        > .container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }


        .main-logo {
            margin-top: 0;

            svg {
                width: 312.5px;
                height: 25px;
            }
        }


        .nav-main {
            margin: 0 0 0 auto;

            &.js-active-menu .nav-main-content {
                transform: translateY(0);
            }

            &-button {
                width: 165px;
                height: 40px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                border: 1px solid $color-second;
                border-radius: 0;
                padding: 0;
                margin: 0;
                cursor: pointer;
                transition: $duration;

                &::before,
                &::after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    background-color: $color-second;
                    transition: $duration;
                }

                &::before {
                    top: -1px;
                    right: 0;
                }

                &::after {
                    right: unset;
                    left: 0;
                    bottom: -1px;
                }

                &:hover,
                &:focus {
                    background-color: transparent;
                    border: 1px solid $color-gray;
                    transition: .3s;

                    &::before,
                    &::after {
                        width: 0;
                        transition: .3s .3s ease-out;
                    }

                    .nav-main-button {
                        &__icon {
                            fill: $color-white;
                        }

                        &__text {
                            color: $color-white;
                        }
                    }
                }

                &.js-active-navmainbutton {
                    .nav-main-button {
                        &__icon,
                        &__text {
                            display: none;

                            &--close {
                                display: block;
                                opacity: 1;
                            }
                        }
                    }
                }

                &__icon {
                    width: 40px;
                    height: 40px;
                    fill: $color-white;
                    transition: $duration;

                    &--close {
                        width: 17.5px;
                        height: 17.5px;
                        margin-right: 10px;
                    }
                }

                &__text {
                    font-family: $font-family--heading;
                    font-size: $font-size--5;
                    line-height: 1.1em;
                    font-weight: 600; 
                    letter-spacing: .10em;
                    color: $color-white;
                    text-transform: uppercase;
                    transition: $duration;
                }

                &__icon,
                &__text {
                    &--close {
                        display: none;
                        opacity: 0;
                    }
                }
            }

            &-content {
                width: 100%;
                height: auto;
                position: fixed;
                top: 80px;
                left: 0;
                transform: translateY(calc(-100% - 80px));
                z-index: -1;
                background-color: $color-dark;
                border-bottom: 1px solid $color-light;
                padding: 80px 0;
                transition: $duration;

                &__container {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            &__close {
                display: none;
            }

            .menu {
                width: auto;
                row-gap: 40px;
                overflow-y: auto;
            }

            .submenu {
                width: 785px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                top: 0;
                right: calc(( (100% - $large) / 2 ) + 15px);
                background-color: #12223E;
                padding: 30px;
                border-radius: 0;
                transition: $duration;

                &__nav {
                    width: 100%;
                    max-width: 100%;
                    column-count: 2;
                    gap: 7.5px 30px;
                    overflow-y: auto;
                }

                &__close,
                .menu__item {
                    display: none;
                }
            }
        }


        .tools {
            margin: 0 0 0 30px;

            &__item,
            &-translate,
            #accessconfig {
                width: 40px;
                height: 40px;
                border-radius: 0;

                &::after {
                    border-radius: 0;
                }

                &--translate {
                    svg {
                        margin-top: -3px;
                    }
                }

                &--accessibility {
                    svg {
                        margin-top: 4px;
                    }
                }

                &--search {
                    svg {
                        margin-top: -4px;
                    }
                }
            }
        }
    }
}



//======================================================================================================
// Logo
//======================================================================================================
.main-logo {
    position: relative;
    display: flex;
    margin-top: 60px;
    transition: $duration;

    svg {
        width: 230px;  
        height: 135px;
        transition: $duration;
    }

    &__title {
        display: none;
    }
}



//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    column-gap: 15px;
    margin-top: 62.5px;

    &-translate,
    #accessconfig {
        width: 40px;
        height: 45px;
    }

    &__item {
        width: 40px;
        height: 45px;
        position: relative;
        background-color: transparent;
        border: 1px solid $color-gray;
        border-radius: 50px 50px 0 0;
        padding: 0;
        margin: 0;
        transition: $duration;

        &:hover,
        &:focus {
            background-color: transparent;
            border-color: $color-second;

            svg {
                fill: $color-white;
            }
        }

        &--reverse {
            border-radius: 0 0 50px 50px;

            &::after {
                border-radius: 0 0 50px 50px;
            }
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }

        svg {
            width: 100%;
            height: 100%;
            display: flex;
            fill: $color-white;
            transition: $duration;
        }
    }

    // Google translate
    &-translate {
        position: relative;

        &__wrapper {
            width: max-content;
            @include absolute($left: 50%, $top: 65px);
            z-index: 10;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            padding: 15px 20px 30px;
            background-color: $color-light;
            border-radius: $border-radius;
            transform: translateX(-50%);
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                @include absolute($top: -6px, $left: 50%);
                transform: translateX(-50%) rotate(45deg);
                z-index: -1;
                display: block;
                @include size(30px);
                background-color: $color-light;
                pointer-events: none;
            }

            a.glink {        
                &:hover, 
                &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            width: 30px;
            height: 30px;
            @include absolute($bottom: -10px, $left: 50%);
            @include flex($alignItems: center, $justifyContent: center);
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-main;
            border: none;

            svg {
                width: 45%;
                height: 45%;
                fill: $color-white;
            }

            &:hover, 
            &:focus {
                background-color: $color-second;

                svg {
                    fill: $color-text;
                }
            }
        }
    }
}





//======================================================================================================
// RESPONSIVE
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {    
        .header {
            height: 670px;
        
            &__container {
                &::before {
                    left: -350px;
                }

                &::after {
                    height: 611px;
                    left: 329px;
                    border-radius: 160px 0 0 0;
                }
            }

            &__sticky {
                .nav-main {
                    &-content {
                        padding: 60px 0;
                    }

                    .submenu {
                        width: 620px;
                        right: calc(( (100% - $medium) / 2 ) + 10px);
                        padding: 20px;
                    }
                }
            }
        }
        

        .tools {
            margin-top: 50px;
        }
    }



    // 960
    @media screen and (max-width: $medium) {   
        body {
            &.admin-bar {
                .header__sticky,
                .header__sticky .nav-main-content {
                    top: unset;
                }

                .header__sticky .nav-main {
                    &-content {
                        padding-top: 80px;
                    }

                    .submenu {
                        padding-top: 70px;
                    }
                }
            }

            &.js-active-navmain {
                overflow: hidden;
            } 
        }

        
        
        .header {
            height: auto;
        
            &__container {
                &::before {
                    left: -390px;
                }

                &::after {
                    display: none;
                }
            }

            // Menu when scrolling on the page
            &__sticky {
                height: 70px;
                top: unset;
                bottom: 0;
                border-bottom: 0;
                opacity: 1;
                visibility: visible;

                &::after {
                    top: 0;
                    bottom: unset;
                }


                .main-logo {
                    display: none;
                }


                .nav-main {
                    display: flex;
                    margin: 0 10px 0 0;
        
                    &-content {
                        height: calc(100% - 70px);
                        top: unset;
                        bottom: 70px;
                        transform: translateY(100%);
                        border-bottom: none;
                        padding: 40px 30px;

                        &__container {
                            max-width: 100%;
                            padding: 0;
                        }
                    }
        
                    .menu {
                        width: 100%;

                        &__item.menu-item-has-children.js-active-submenu .submenu {
                            right: 0;
                        }
                    }
        
                    .submenu {
                        width: 100%;
                        height: 100%;
                        right: -100%;
                        justify-content: flex-start;
                        row-gap: 30px;
                        padding: 30px 30px 50px;
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;

                        &::after {
                            display: none;
                        }
        
                        &__close {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: transparent;
                            border-color: $color-light;
                            border-radius: 0;
                            padding: 0;
                            margin: 0 0 0 10px;
                            transition: $duration;
        
                            &:hover,
                            &:focus {
                                background-color: $color-light;
                                border-color: $color-light;

                                svg {
                                    fill: none;
                                    stroke: $color-text;
                                }
                            }
        
                            svg {
                                width: 20px;
                                height: 20px;
                                fill: none;
                                stroke-width: 1.5px;
                                stroke: $color-white;
                                transform: rotate(-90deg);
                                transition: $duration;
                            }
                        }
        
        
                        .menu__item {
                            display: flex;
                            margin: 0 0 10px 10px;
                        }
                    }
                }


                .tools {
                    display: flex;
                    column-gap: 10px;
                    margin: 0 40px 0 auto;
                }
            }
        }


        .main-logo {
            position: relative;
            z-index: 2; // Allow click on logo and bigger z-index than ALU/Top-post
            margin: 50px 30px 0 auto;
        }
        

        .tools {
            display: none;

            // Google translate en attente
            &-translate {
                &__wrapper {
                    top: unset;
                    bottom: 70px;
                    padding: 30px 20px 15px;

                    &::before {
                        top: unset;
                        bottom: -6px;
                    }
                }

                &__close {
                    top: -10px;
                    bottom: unset;
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .header {
            &__container::before {
                width: 300px;
                height: 290px;
                top: -80px;
                left: -175px;
            }

            // Menu when scrolling on the page
            &__sticky {
                .nav-main {        
                    .submenu {
                        &__nav {
                            column-count: 1;
                        }
                    }
                }
            }
        }

        .main-logo  {
            width: 40%;
            max-width: 230px;
            margin: 5% 20px 0 auto;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }



    // 540
    @media screen and (max-width: 540px) {
        .header__sticky {
            .nav-main {
                &-button {
                    width: 40px;

                    &.js-active-navmainbutton {
                        .nav-main-button__text {
                            display: none;
                        }
                    }

                    &__icon {
                        &--close {
                            margin-right: 0;
                        }
                    }

                    &__text {
                        display: none;
                    }
                }
            }
        }
    }



    // 380
    @media screen and (max-width: 380px) {
        .header__sticky {
            .tools {
                margin-right: 10px;
            }
        }
    }