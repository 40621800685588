//======================================================================================================
// GENERAL
//======================================================================================================
body.home {
    h2,
    h3 {
        &::before {
            display: none;
        }
    }
}

#content {
    margin-top: -660px;
}

.home {
    &-button {
        min-height: 80px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $btn-font-family;
        font-size: $btn-font-size;
        line-height: $btn-line-height;
        font-weight: $btn-font-weight;
        color: $btn-color;
        letter-spacing: .10em;
        text-transform: $btn-text-transform;
        text-decoration: none;
        text-align: center;
        background: $btn-bg;
        border: $btn-border-width $btn-border-style $btn-border-color;
        transition: $duration;

        &::before,
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            background-color: $btn-border-color;
            transition: $duration;
        }

        &::before {
            top: -1px;
            right: 0;
        }

        &::after {
            right: unset;
            left: 0;
            bottom: -1px;
        }

        &:hover,
        &:focus {
            color: $color-text;
            background-color: $btn-bg;
            border: 1px solid $color-second;
            transition: .3s;

            &::before,
            &::after {
                width: 0;
                transition: .3s .3s ease-out;
            }
        }

        &--second {
            color: $color-white;
            border-color: $color-second;

            &::before,
            &::after {
                background-color: $color-second;
            }

            &:hover,
            &:focus {
                color: $color-white;
                border: 1px solid $color-light;
            }
        }

        &--light {
            color: $color-white;
            border-color: $color-light;

            &::before,
            &::after {
                background-color: $color-light;
            }

            &:hover,
            &:focus {
                color: $color-white;
                border: 1px solid $color-second;
            }
        }

        &--gray {
            color: $color-white;
            background-color: $color-gray;
            border-color: $color-gray;
        }
    }

    &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: toRem(55);
        line-height: 1.1em;
        color: $color-text;
        letter-spacing: .05em;
        text-transform: uppercase;
        margin: 0;

        span {
            font-size: toRem(30);
            line-height: 1.2em;
            font-weight: 400;
            letter-spacing: .05em;
            padding-left: 160px;
        }

        &--white {
            color: $color-white;
        }

        &--agenda {
            span {
                padding-left: 170px;
            }
        }

        &--contact {
            span {
                padding-left: 102.5px;
            }
        }

        &--sw,
        &--kiosque {
            span {
                padding-left: 0;
            }
        }

        &--partenaires {
            align-items: center;

            span {
                padding-left: 230px;
            }
        }
    }
}





//======================================================================================================
// ALU
//======================================================================================================
.alu {
    background-color: $color-dark;

    * {
        box-sizing: border-box;
    }

    .swiper-container {
        width: 785px;
        margin: 0;
    }

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__media {
        width: 785px;
        height: 660px;
        position: relative;
        display: flex;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(360deg, rgba(0, 16, 45, 0.5) 0%, rgba(0, 16, 45, 0.075) 32.31%, rgba(0, 16, 45, 0.075) 49.48%, rgba(0, 16, 45, 0.075) 100%);
        }

        &--responsive {
            display: none;
        }

        svg {
            width: 0;
            height: 0;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--video {
        .alu {
            &__media {
                clip-path: url(#shapeVideoView);
            }
        }
    }

    &__shape {
        &--1200,
        &--960,
        &--640 {
            display: none;
        }
    }
}





//======================================================================================================
// ACTUALITES
//======================================================================================================
.ac {
    position: relative;
    padding: 100px 0 120px;
    overflow: hidden;

    &::before {
        content: '';
        width: 330px;
        height: 330px;
        position: absolute;
        left: 0;
        bottom: -135px;
        z-index: -1;
        background-color: $color-main;
        border-radius: 0 250px 250px 0;
    }

    &__shape {
        width: 360px;
        height: 150px;
        position: absolute;
        top: 110px;
        right: 0;
        z-index: -1;
        background-color: $color-second;
        border-radius: 100px 0 0 100px;

        &::before {
            content: '';
            width: 240px;
            height: 145px;
            position: absolute;
            right: 0;
            bottom: calc(100% + 30px);
            background-color: $color-third;
            border-radius: 100px 0 0 100px;
        }
    }

    &__container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        margin-top: 30px;
    }

    &-bigitem {
        display: flex;
        align-items: flex-end;
        column-gap: 40px;

        &:hover,
        &:focus {
            color: $color-text;

            .ac-bigitem {
                &__image img {
                    transform: scale(1.1);
                }

                &__title {
                    color: $color-main;
                }
            }
        }

        &__image {
            width: 700px;
            height: 320px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__content {
            width: 315px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 32.5px;
        }

        &__category {
            font-size: $font-size--5;
            line-height: 1.4em;
            letter-spacing: .1em;
            text-transform: uppercase;
            margin-bottom: 7.5px;
        }

        &__title {
            font-size: $font-size--3;
            line-height: 1.2em;
            margin: 0;
            transition: $duration;
        }

        &__text {
            margin-top: 10px;
        }
    }

    &__smallitems-button {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__smallitems {
        display: flex;
        justify-content: flex-end;
        column-gap: 30px;
    }

    &-smallitem {
        width: 260px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;

        &:hover,
        &:focus {
            color: $color-text;

            .ac-smallitem {
                &__image img {
                    transform: scale(1.1);
                }

                &__category {
                    background-color: $color-main;
                }

                &__title {
                    color: $color-main;
                }
            }
        }

        &__image {
            width: 100%;
            height: 200px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__category {
            max-width: 95%;
            min-height: 30px;
            position: absolute;
            bottom: 10px;
            left: 0;
            font-size: $font-size--text-small;
            line-height: 1.45em;
            color: $color-white;
            letter-spacing: .1em;
            text-transform: uppercase;
            background-color: $color-dark;
            border-radius: 0 20px 20px 0;
            padding: 5px 15px 5px 10px;
            transition: $duration;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.35em;
            margin: 0;
        }

        &__text {
            margin-top: 2.5px;
        }
    }

    &__button {
        width: 300px;
    }
}





//======================================================================================================
// AGENDA
//======================================================================================================
.ag {
    position: relative;
    background-color: $color-dark;
    padding: 80px 0 180px;

    &::before {
        content: url($urlShort + 'home-icons/backgrounds/forme_agenda.svg');
        width: 450px;
        height: 510px;
        position: absolute;
        bottom: 145px;
        left: -140px;
    }

    &__container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 27.5px;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }

    &__bigitems {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 30px;
    }

    &-item {
        display: flex;

        &:hover,
        &:focus {
            .ag-item {
                &__date::before {
                    width: 30px;
                    background-color: $color-light;
                }

                &__image img {
                    transform: scale(1.1);
                }
            }
        }

        &--big {
            width: 570px;
            height: 280px;
        }

        &--small {
            width: 405px;
            height: 250px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            background-color: rgba($color-light, .1);
            padding: 10px 25px 30px;
        }

        &__image {
            width: 300px;
            height: 100%;
            flex-shrink: 0;
            overflow: hidden;

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-bg--image no-repeat center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }
        }

        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            background-color: $color-dark;
            border: 1px solid $color-light;
            border-left: none;
            padding: 10px 10px 27.5px 25px;
        }

        &__category {
            font-size: $font-size--text-small;
            line-height: 1.45em;
            color: $color-white;
            letter-spacing: .1em;
            text-transform: uppercase;
            margin-bottom: 12.5px;
        }

        &__date {
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 10px;
            font-size: $font-size--text-medium;
            line-height: 1em;
            font-weight: 700;
            color: $color-white;
            letter-spacing: .1em;
            text-transform: uppercase;
            margin-bottom: 17.5px;

            &::before {
                content: '';
                width: 15px;
                height: 15px;
                background-color: $color-second;
                border-radius: 0 20px 20px 0;
                transition: $duration;
            }
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.3em;
            color: $color-white;
            margin: 0;
        }
    }

    &__smallitems-buttons {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        column-gap: 30px;
    }

    &__smallitems {
        display: flex;
        column-gap: 30px;
    }

    &__buttons {
        display: flex;
        gap: 30px;
    }

    &__button {
        width: 300px;
    }
}





//======================================================================================================
// CONTACT
//======================================================================================================
.cntc {
    width: 100%;
    position: relative;
    background-color: $color-third;

    &__container {
        width: 100%;
        height: 175px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $color-white;
        padding: 0 55px 0 50px;
        transform: translateY(-60px);
    }

    &__content {
        display: flex;
        column-gap: 30px;
    }

    &-item {
        width: 205px;
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 15px;

        &:nth-child(odd) {
            .cntc-item__icon {
                border-radius: 50px 50px 0 0;
            }
        }

        &:nth-child(even) {
            .cntc-item__icon {
                border-radius: 0 0 50px 50px;
            }
        }

        &:hover,
        &:focus {
            .cntc-item__icon {
                border-color: $color-second;
            }
        }

        &__icon {
            width: 50px;
            height: 55px;
            position: relative;
            border: 1px solid $color-light;
            transition: $duration;

            svg {
                width: 100%;
                height: 100%;
                fill: $color-text;
            }
        }

        &__text {
            font-family: $font-family--heading;
            font-size: $font-size--5;
            line-height: 1.4em;
            font-weight: 500;
            color: $color-text;

            span {
                font-weight: 700;
            }
        }
    }
}






//======================================================================================================
// SOCIAL WALL
//======================================================================================================
.sowl {
    background-color: $color-third;
    padding: 40px 0 100px;

    &__header {
        width: 300px;
        display: flex;
        flex-direction: column;
        row-gap: 40px;

        &--responsive {
            display: none;
        }
    }

    &-socials {
        display: flex;
        column-gap: 10px;
        margin-left: 400px;

        &__item {
            width: 40px; 
            height: 40px;

            &:hover,
            &:focus {
                svg {
                    fill: $color-gray;
                }
            }

            svg {
                width: 100%;
                height: 100%;
                fill: $color-text;
                transition: $duration;
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        column-gap: 30px;
        margin-top: 30px;
    }


    #ff-stream-1 {
        width: 770px;
        min-height: auto !important;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        padding: 0;
        margin: 0;
    
        .ff-stream-wrapper {
            width: 100%;
            height: 235px !important;
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 0;
        }
    
        .ff-item {
            width: 370px !important;
            height: 100%;
            position: relative !important;
            overflow: hidden;
            transform: none !important;
            visibility: inherit !important;

            &:first-child {
                transform: translateY(-70px) !important;
            }
    
            &:hover,
            &:focus {
                .picture-item__inner::before {
                    opacity: 1;
                }
    
                .ff-content,
                .ff-item-bar {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
    
            .picture-item__inner {
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                color: $color-white;
                background-color: $color-bg--image;
                box-shadow: none;

                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    z-index: 2;
                    background: rgba($color-dark, .95);
                    margin: 0;
                    opacity: 0;
                    transition: $duration;
                }
            }
    
            .ff-item-cont {
                height: 100%;
            }
    
            .ff-img-holder {
                height: 100%; 
    
                img {
                    width: 100%;
                    height: auto;
                    min-height: 100% !important;
                    object-fit: cover;
                }
            }
    
            .ff-content {
                width: 100%;
                height: auto !important;
                max-height: 195px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                font-size: $font-size--text;
                font-weight: $font-weight-normal;
                color: $color-white;
                padding: 20px 30px;
                margin: 0;
                opacity: 0;
                pointer-events: none;
                transition: $duration;
    
                a {
                    color: $color-second;
                }
            }
    
            .ff-item-meta {
                display: none;
            }
    
            .ff-item-bar {
                width: 100%;
                height: 40px;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 2;
                display: flex;
                align-items: center;
                padding: 0 30px;
                border-top: none;
                opacity: 0;
                pointer-events: none;
                transition: $duration;
    
                a {
                    color: $color-white !important;
                }
    
                .ff-share-wrapper {
                    top: 0;
                    margin: 0 0 0 auto;
    
                    .ff-icon-share:before {
                        color: $color-white;
                    }
    
                    .ff-share-popup {
                        right: 0;
                        bottom: 25px;
                        background-color: $color-white;
                        border-color: $color-white;
    
                        &::before,
                        &::after {
                            display: none;
                        }
    
                        a {
                            color: $color-text !important;
                        }
                    }
                }
            }
        }
    
        .ff-carousel-icon,
        .ff-video-icon,
        .ff-loadmore-wrapper,
        .shuffle__sizer {
            display: none;
        }
    }
}





//======================================================================================================
// KIOSQUE
//======================================================================================================
.kio {
    position: relative;
    padding: 100px 0 140px;

    &::before {
        content: url($urlShort + 'home-icons/backgrounds/forme_kiosque.svg');
        width: 465px;
        height: 610px;
        position: absolute;
        top: -210px;
        right: calc(100% - ( (100% - $large) / 2 ) - 50px );
    }

    &__title {
        &--responsive {
            display: none;
        }
    }

    &-item {
        display: flex;
        column-gap: 170px;
        margin-left: 100px;

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding-top: 15px;
        }

        &__content {
            width: 315px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &__category {
            font-size: $font-size--text-medium;
            line-height: 1.25em;
            letter-spacing: .05em;
            text-transform: uppercase;
        }

        &__title {
            font-size: $font-size--3;
            line-height: 1.2em;
            margin: 15px 0 0;
        }

        &__text {
            margin-top: 10px;
        }

        &__image-buttons {
            position: relative;
            display: flex;
            align-items: flex-end;
            column-gap: 45px;

            &::after {
                content: '';
                width: 3000px;
                height: 245px;
                position: absolute;
                left: -115px;
                bottom: -60px;
                z-index: -1;
                background-color: $color-main;
                border-radius: 150px 0 0 150px;
            }
        }

        &__image {
            width: 285px;
            height: 405px;
            box-shadow: 1px 1px 30px 0px #0000000D;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) $color-bg--image no-repeat center;
            }
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            row-gap: 50px;
        }

        &-button {
            display: flex;
            align-items: center;
            column-gap: 15px;

            &:hover,
            &:focus {
                .kio-item-button__icon {
                    border-color: $color-second; 
                }
            }

            &--listen,
            &--feuilleter {
                .kio-item-button {
                    &__icon svg {
                        fill: $color-text;
                    }

                    &__text {
                        color: $color-text;
                    }
                }

                &:hover,
                &:focus {
                    .kio-item-button__icon {
                        border-color: $color-main; 
                    }
                }
            }

            &__icon {
                width: 50px;
                height: 55px;
                border: 1px solid $color-light;
                border-radius: 50px 50px 0 0;
                transition: $duration;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-white;
                    transition: $duration;
                }
            }

            &__text {
                font-family: $font-family--heading;
                font-size: $font-size--5;
                line-height: 1.4em;
                font-weight: 700;
                color: $color-white;
                transition: $duration;
            }
        }
    }


    &__button {
        width: 300px;
    }
}





//======================================================================================================
// PARTENAIRES
//======================================================================================================
.prtn {
    position: relative;

    &__title {
        width: 100%;
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 1;
    }

    &__items {
        width: 100%;
        display: flex;
    }

    &-item {
        width: 25%;
        height: 500px;
        position: relative;
        transition: $duration;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.24) 31.98%, rgba(0, 0, 0, 0) 56.47%, rgba(0, 0, 0, 0.075) 77.33%, rgba(0, 0, 0, 0.24) 86.24%, rgba(0, 0, 0, 0.24) 100%);
            transition: $duration;
        }

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba($color-dark, .4);
            opacity: 0;
            transition: $duration;
        }

        &:hover,
        &:focus {
            width: 40%;

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }

            .prtn-item__title {
                column-gap: 10px;
            }
        }

        &__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__title {
            width: 100%;
            position: absolute;
            left: 50%;
            bottom: 30px;
            transform: translateX(-50%);
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 0;
            font-size: $font-size--text-medium;
            line-height: 1.25em;
            font-weight: 500;
            color: $color-white;
            letter-spacing: .05em;
            text-transform: uppercase;
            text-align: center;
            padding: 0 5%;
            margin: 0;
            transition: $duration;

            svg {
                width: 25px;
                height: 25px;
                fill: $color-white;
                transform: rotate(90deg);
            }
        }
    }
}





//======================================================================================================
// RESPONSIVE
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) { 
        //======================================================================================================
        // GENERAL
        //======================================================================================================
        #content {
            margin-top: -610px;
        }

        .home-title {
            &--contact {
                font-size: toRem(40);
                line-height: 1.25em;
        
                span {
                    font-size: toRem(25);
                    padding-left: 60px;
                }
            }
        }



        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            .swiper-container {
                width: 620px;
            }

            &__media {
                width: 620px;
                height: 610px;
            }

            &__shape {
                display: none;

                &--1200 {
                    display: block;
                }
            }

            &--video {
                .alu {
                    &__media {
                        clip-path: url(#shapeVideoView2);
                    }
                }
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {
            padding: 100px 0 95px;
        
            &::before {
                width: 292.5px;
            }
        
            &__shape {
                width: 270px;
                top: 97.5px;
        
                &::before {
                    width: 150px;
                }
            }
        
            &__content {
                row-gap: 20px;
            }
        
            &-bigitem {
                align-items: center;
                column-gap: 30px;
        
                &__image {
                    width: 560px;
                    height: 255px;
                }

                &__content {
                    margin-bottom: 0;
                }
            }
        
            &__smallitems {
                column-gap: 20px;
            }
        
            &-smallitem {
                width: 200px;
        
                &__image {
                    height: 155px;
                }
            }
        
            &__button {
                width: 280px;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {        
            padding: 80px 0 160px;

            &::before {
                bottom: 125px;
            }
        
            &__content {
                row-gap: 20px;
            }

            &__bigitems {
                gap: 20px;
            }
        
            &-item {
                &:hover,
                &:focus {
                    .ag-item__date::before {
                        width: 25px;
                    }
                }

                &--big {
                    width: 460px;
                    height: 260px;
                }
        
                &--small {
                    width: 310px;
                    height: 230px;
                    padding: 10px 25px 30px;
                }
        
                &__image {
                    width: 210px;
                }
        
                &__content {
                    padding: 10px 20px 27.5px;
                }
            }
        
            &__smallitems-buttons {
                column-gap: 20px;
            }
        
            &__smallitems {
                column-gap: 20px;
            }
        
            &__buttons {
                row-gap: 20px;
            }
        
            &__button {
                width: 280px;
            }
        }





        //======================================================================================================
        // CONTACT
        //======================================================================================================
        .cntc {        
            &__container {
                height: 170px;
                padding: 0 30px;
            }
        
            &__content {
                column-gap: 25px;
            }

            &-item {
                width: 175px;

                &:last-child {
                    width: 195px;
                }
            }
        }






        //======================================================================================================
        // SOCIAL WALL
        //======================================================================================================
        .sowl {
            padding: 40px 0 100px;
        
            &__header {
                row-gap: 27.5px;
            }
        
            &-socials {
                margin-left: 320px;
            }
        
            &__content {
                justify-content: space-between;
                column-gap: unset;
                margin-top: 20px;
            }
        
        
            #ff-stream-1 {
                width: 620px;
            
                .ff-stream-wrapper {
                    height: 200px !important;
                }
            
                .ff-item {
                    width: 300px !important;
        
                    &:first-child {
                        transform: translateY(-60px) !important;
                    }
            
                    .ff-content {
                        max-height: 170px;
                        font-size: $font-size--text-small;
                        padding: 15px 20px;
                    }
            
                    .ff-item-bar {
                        height: 30px;
                        padding: 0 20px;
                    }
                }
            }
        }





        //======================================================================================================
        // KIOSQUE
        //======================================================================================================
        .kio {        
            &::before {
                width: 360px;
                height: 475px;
                top: -165px;
                right: calc(100% - ( (100% - $medium) / 2 ) - 30px );
            }
        
            &-item {
                column-gap: 160px;
                margin-left: 80px;
        
                &__container {
                    padding-top: 0;
                }    
        
                &__image-buttons {
                    column-gap: 30px;
        
                    &::after {
                        left: -125px;
                    }
                }
        
                &__image {
                    width: 275px;
                    height: 390px;
                }
        
                &-button {        
                    &__text {
                        display: none;
                    }
                }
            }
        
        
            &__button {
                width: 280px;
            }
        }





        //======================================================================================================
        // PARTENAIRES
        //======================================================================================================
        .prtn {                
            &-item { 
                height: 450px;       

                &__title {
                    font-size: $font-size--text;
                    line-height: 1.5em;
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        //======================================================================================================
        // GENERAL
        //====================================================================================================== 
        #content {
            margin-top: -105px;
        }

        .home {
            &-button {
                min-height: 70px;
            }

            &-title {
                font-size: toRem(40);
                line-height: 1.25em;
        
                span {
                    font-size: toRem(25);
                    padding-left: 92.5px;
                }

                &--agenda {
                    span {
                        padding-left: 112.5px;
                    }   
                }

                &--contact {
                    span {
                        padding-left: 55px;
                    }
                }

                &--sw,
                &--kiosque {
                    span {
                        padding-left: 0;
                    }
                }

                &--partenaires {
                    span {
                        padding-left: 145px;
                    }
                }
            }
        }

        

        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            &--video {
                .alu__media {
                    display: none;

                    &--responsive {
                        display: flex;
                    }
                }
            }

            &__item {
                width: 100%;
            }

            &__media {
                width: 100%;
                height: 580px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            &__shape {
                &--1200 {
                    display: none;
                }

                &--960 {
                    display: block;
                }
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {
            padding: 80px 0 70px;
        
            &::before {
                width: 330px;
                height: 230px;
                bottom: -130px;
            }
        
            &__shape {
                width: 240px;
                height: 100px;
                top: 65px;
        
                &::before {
                    width: 160px;
                    height: 95px;
                    bottom: calc(100% + 20px);
                }
            }
        
            &-bigitem {
                column-gap: 20px;
        
                &__image {
                    width: 320px;
                    height: 200px;
                }

                &__content {
                    width: 280px;
                }

                &__category {
                    font-size: $font-size--text;
                    line-height: 1.25em;
                    margin-bottom: 12.5px;
                }

                &__title {
                    font-size: $font-size--4;
                    line-height: 1.3em;
                }

                &__text {
                    font-size: $font-size--text-small;
                    line-height: 1.45em;
                    margin-top: 7.5px;
                }
            }

            &__smallitems-button {
                flex-direction: column;
            }
        
            &__smallitems {
                justify-content: flex-start;
                column-gap: 17px;
            }

            &-smallitem {
                width: 195px;

                &__title {
                    font-size: $font-size--5;
                    line-height: 1.4em;
                }

                &__text {
                    font-size: $font-size--text-small;
                    line-height: 1.45em;
                    margin-top: 5px;
                }
            }
        
            &__button {
                width: 250px;
                margin: 25px 0 0 auto;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {   
            padding-bottom: 145px;
            
            &::before {
                display: none;
            }

            &__container {
                row-gap: 40px;
            }
        
            &-item {
                &--big {
                    width: 300px;
                    height: 460px;
                    flex-direction: column;
                }
        
                &--small {
                    width: 300px;
                    padding: 10px 20px 30px;
                }
        
                &__image {
                    width: 100%;
                    height: 280px;
                }
        
                &__content {
                    height: 180px;
                    justify-content: center;
                    border: 1px solid $color-light;
                    border-top: none;
                    padding: 10px 20px 10px;
                }

                &__date {
                    font-size: $font-size--5;
                    line-height: 1.1em;
                }

                &__title {
                    font-size: $font-size--5;
                    line-height: 1.45em;
                }
            }
        
            &__smallitems-buttons {
                flex-direction: column;
                align-items: flex-end;
                row-gap: 30px;
            }
        
            &__smallitems {
                width: 100%;
            }
        
            &__buttons {
                flex-direction: row;
                column-gap: 20px;
                justify-content: flex-end;
            }
        
            &__button {
                width: 250px;
            }
        }





        //======================================================================================================
        // CONTACT
        //======================================================================================================
        .cntc {        
            &__container {
                height: auto;
                flex-direction: column;
                justify-content: center;
                row-gap: 30px;
                padding: 30px 20px;
                transform: translateY(-75px);
            }
        
            &__content {
                justify-content: center;
                column-gap: 12.5px;
            }
        
            &-item {  
                width: 180px;
                
                &:last-child {
                    width: 180px;
                }

                &__text {
                    font-size: $font-size--text;
                    line-height: 1.5em;
                }
            }
        }






        //======================================================================================================
        // SOCIAL WALL
        //======================================================================================================
        .sowl {
            padding: 5px 0 90px;

            &__header-socials {
                display: flex;
                align-items: center;
                column-gap: 20px;
            }
        
            &__header {
                display: none;
                row-gap: 20px;

                &--responsive {
                    display: flex;
                }
            }
        
            &-socials {
                margin: 0 0 30px 0;
            }
        
            &__content {
                margin-top: 32.5px;
            }
        
        
            #ff-stream-1 {
                width: 100%;

                .ff-item {        
                    &:first-child {
                        transform: translateY(0) !important;
                    }

                    &:nth-child(2) {
                        transform: translateY(-95px) !important;
                    }

                    .ff-content {
                        font-size: $font-size--text-xsmall;
                    }
                }
            }
        }





        //======================================================================================================
        // KIOSQUE
        //======================================================================================================
        .kio {     
            padding: 62.5px 0 80px;
            
            &::before {
                display: none;
            }
        
            &-item {
                width: 100%;
                justify-content: space-between;
                align-items: center;
                column-gap: 0;
                margin-left: 0;
        
                &__container {
                    height: 355px;
                    padding-top: 0;
                }  
                
                &__content {
                    width: 270px;
                }

                &__title {
                    font-size: $font-size--4;
                    line-height: 1.3em;
                }

                &__category {
                    font-size: $font-size--5;
                    line-height: 1.4em;
                }

                &__text {
                    font-size: $font-size--text-small;
                    line-height: 1.45em;
                }
        
                &__image-buttons {
                    column-gap: 20px;
                    margin-bottom: 10px;
        
                    &::after {
                        left: -85px;
                        bottom: -40px;
                    }
                }
        
                &__image {
                    width: 200px;
                    height: 285px;
                }

                &__buttons {
                    row-gap: 20px;
                }
        
                &-button {  
                    &--listen,
                    &--feuilleter {
                        .kio-item-button__icon svg {
                            fill: $color-white;
                        } 

                        &:hover,
                        &:focus {
                            .kio-item-button__icon {
                                border-color: $color-second;
                            }
                        }
                    }

                    &__icon {
                        width: 40px;
                        height: 45px;
                    }
                }
            }
        
        
            &__button {
                width: 250px;
            }
        }





        //======================================================================================================
        // PARTENAIRES
        //======================================================================================================
        .prtn {    
            &__items {
                flex-wrap: wrap;
            }
            
            &-item { 
                width: 50%;
                height: 350px;    
                
                &::before {
                    background-color: rgba($color-dark, .3);
                }

                &::after {
                    display: none;
                }

                &:hover,
                &:focus {
                    width: 50%;

                    &::before {
                        background-color: rgba($color-dark, .5);
                        opacity: 1;
                    }
                }

                &__title {
                    bottom: 20px;
                    font-size: $font-size--text-medium;
                    line-height: 1.25em;
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) { 
        //======================================================================================================
        // GENERAL
        //======================================================================================================
        #content {
            margin-top: -15%;
        }   

        .home-button {
            min-height: 70px;
        }

        .home-title--partenaires {
            color: $color-text;

            span {
                padding-left: 130px;
            }
        }
        
        



        //======================================================================================================
        // ALU
        //======================================================================================================
        .alu {
            .swiper-container {
                width: 100%;
            }

            &__media {
                aspect-ratio: 1;
                width: auto;
                height: auto;
                max-height: 555px;
            }

            &__shape {
                &--960 {
                    display: none;
                }

                &--640 {
                    display: block;
                }
            }
        }





        //======================================================================================================
        // ACTUALITES
        //======================================================================================================
        .ac {
            padding: 60px 0;
        
            &::before,
            &__shape {
                display: none;
            }

            &__content {
                row-gap: 30px;
            }
        
            &-bigitem {
                width: 100%;
                flex-direction: column;
                row-gap: 10px;
        
                &__image,
                &__content {
                    width: 100%;
                }

                &__category {
                    font-size: $font-size--text-small;
                    line-height: 1.45em;
                    margin-bottom: 10px;
                }

                &__title {
                    font-size: $font-size--text-medium;
                    line-height: 1.25em
                }

                &__text {
                    margin-top: 10px;
                }
            }
        
            &__smallitems {
                width: 100%;
                flex-direction: column;
                row-gap: 20px;
            }

            &-smallitem {
                width: 100%;
            }
        
            &__button {
                margin: 35px 0 0 0;
            }
        }





        //======================================================================================================
        // AGENDA
        //======================================================================================================
        .ag {   
            padding: 60px 0 120px;

            &__bigitems {
                flex-direction: column;
                row-gap: 20px;
            }
        
            &-item {
                &--big {
                    width: 100%;
                    height: auto;
                }
        
                &--small {
                    width: 100%;
                    height: 200px;
                }
        
                &__content {
                    height: auto;
                    padding: 30px 20px 27.5px;
                }
            }
        
            &__smallitems-buttons {
                align-items: flex-start;
            }

            &__smallitems {
                flex-direction: column;
                row-gap: 20px;
            }
        
            &__buttons {
                flex-wrap: wrap;
                justify-content: flex-start;
            }
        }





        //======================================================================================================
        // CONTACT
        //======================================================================================================
        .cntc {        
            &__container {
                justify-content: center;
                padding: 30px 10px;
                transform: translateY(-60px);
            }
        
            &__content {
                flex-wrap: wrap;
                column-gap: 30px;
                row-gap: 30px;
            }
        }






        //======================================================================================================
        // SOCIAL WALL
        //======================================================================================================
        .sowl {
            padding: 0px 0 60px;

            &__header-socials {
                flex-direction: column;
                align-items: flex-start;
                column-gap: 0;
                row-gap: 30px;
            }
        
            &__header {
                width: 100%;
            }
        
            &-socials {
                margin: 0;
            }        
        
            #ff-stream-1 {
                .ff-stream-wrapper {
                    height: auto !important;
                    flex-direction: column;
                    row-gap: 20px;
                }

                .ff-item {    
                    width: 100% !important;
                    height: 200px;

                    &:nth-child(2) {
                        transform: translateY(0) !important;
                    }
                }
            }
        }





        //======================================================================================================
        // KIOSQUE
        //======================================================================================================
        .kio {     
            padding: 60px 0;

            &__title {
                display: none;

                &--responsive {
                    display: flex;
                }
            }
        
            &-item {
                flex-direction: column-reverse;
                align-items: flex-start;
                row-gap: 65px;
                margin-top: 30px;
        
                &__container {
                    height: auto;
                }  
                
                &__content {
                    width: 100%;
                }

                &__title {
                    margin-top: 20px;
                }
        
                &__image-buttons {
                    margin: 0 0 0 20px;
        
                    &::after {
                        left: -85px;
                        bottom: -40px;
                    }
                }
            }

            &__button {
                margin-top: 30px;
            }
        }





        //======================================================================================================
        // PARTENAIRES
        //======================================================================================================
        .prtn { 
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 30px;  

            &__title {
                position: relative;
                top: unset;
                left: unset;
            }
            
            &-item { 
                width: 100%;
                height: 250px;   
                
                &:hover,
                &:focus {
                    width: 100%;
                }
            }
        }
    }