.jobs {

    // TOP POST
    &__intro {
        font-size: 1.25rem; // 20px
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    &__publication-date, &__limit-date {
        display: flex;
        align-items: center;

        & svg {
            width: 26px;
            min-width: 26px;
            height: 26px;
            margin-right: 5px;
        }
    }

    &__publication-date {
        margin-bottom: 5px;
    }

    &__limit-date {
        font-weight: $font-weight-bold;
    }

    // SINGLE    
    &__container {
        display: flex;
    }

    &__informations {
        width: 370px;
    }

    &__details, &__employer {
        border-radius: $border-radius;
        background-color: $color-light;
    }

    &__details {
        padding: 20px 20px 20px 15px;
        margin-bottom: 20px;

        &__postuler {
            margin: 0 0 25px 0;
            width: 100%;
        }

        &__item {
            display: flex;
            align-items: flex-start;
            margin-bottom: 30px;

            & svg {
                width: 40px;
                min-width: 40px;
                height: 40px;
                fill: $color-gray;
                margin-right: 15px;
            }

            &:last-child {
                margin: 0;
            }
        }

        &__label {
            font-size: $font-size--text-small;
            line-height: 1rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }

        &__value {
            font-size: $font-size--text;
        }

        &__content {
            display: flex;
            flex-direction: column;
        }
    }

    &__employer {
        padding: 20px;

        &__header {
            display: flex;
            padding-top: 10px;

            & img {
                height: 100%;
                margin-right: 15px;
            }
        }

        &__informations {
            margin-top: 25px;
            
            &--empty {
                margin-top: 0;
            }
        }

        &__name {
            display: flex;
            flex-direction: column;
            font-size: $font-size--3;
            font-weight: $font-weight-bold;
            line-height: 1.875rem;

            &__label {
                font-size: $font-size--text-small;
                line-height: $font-size--text;
                text-transform: uppercase;
            }
        }

        &__links {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            & a {
                margin-top: 10px;
            }
        }

        &__email, &__url {
            display: flex;
            align-items: center;

            & span {
                text-decoration: underline;
            }

            & svg {
                width: 20px;
                height: 20px;
            }
        }

        &__description {
            margin-top: 25px;

            &__label {
                display: block;
                margin-top: 25px;
                margin-bottom: 10px;
                font-size: $font-size--text-small;
                line-height: $font-size--text;
                font-weight: $font-weight-bold;
                text-transform: uppercase;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &__flexible-content {
        width: 770px;
        margin-top: 0;
        margin-left: 30px;
    }

    // Listing
    &__post-count {
        font-size: $font-size--text-medium;
        font-weight: $font-weight-bold;
        padding-bottom: 0;
        margin-bottom: 30px;
    }
    
    &__proposition {
        margin-bottom: 30px;
    }
}

.card__jobs {
    margin-bottom: 30px;

    &__employer-name, &__localisation {
        display: inline-block;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        font-size: $font-size--text-small;
        margin-bottom: 15px;
    }

    &__employer-name {
        margin-right: 15px;
    }

    &__localisation {
        position: relative;
        padding-left: 30px;

        & svg {
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
            fill: $color-gray;
            background-color: $color-light;
            border-radius: $border-radius--round;
        }
    }

    .card__publication-date {
        margin: 0;
    }
}

// Filtres
.jobs__filters {

    .filter__options {
        padding-bottom: 0;
    }

    & #keywordsFilter {
        height: 68px;
        padding: 24px;
        background-color: $color-white;
        border-color: $color-white;
    }

    & #keywordsReset {
        right: 24px;
    }
}

// Form
#prop_jobs {

    .acf-field-group {
        .acf-label {
            display: none;
        }

        .acf-input {
            .acf-label {
                display: block;
            }
        }
    }

    .acf-field-true-false {
        display: flex;

        .acf-label {
            order: 1;
        }

        .acf-input {
            .acf-true-false {
                label {
                    margin: 0 8px 0 0;
                }
            }
        }
    }

    .acf-url {
        .acf-icon {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .jobs {

        &__flexible-content {
            width: 550px;
            margin-left: 20px;
        }
    }
    
}


// 960
@media screen and (max-width: $medium) {

    .jobs {

        &__container {
            flex-direction: column;
        }

        &__informations {
            width: 100%;
        }

        &__details {
            &__list {
                column-count: 2;
                column-gap: 20px;
                gap: 20px;
            }
        }

        &__employer {
            margin-bottom: 20px;
        }

        &__flexible-content {
            width: 100%;
            margin: 0;
        }
    }

}


// 640
@media screen and (max-width: $small) {
    
    .jobs {
        &__details {
            &__list {
                column-count: 1;
            }
        }
    }

}