.bloc-content--404 {
    h2 {
        justify-content: center;
        margin: 0;
        border: 0;
        @include font-size(200);
        text-align: center;

        &::before {
            display: none;
        }
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;

    .search-container {
        display: flex;
        position: relative;
        align-items: center;
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            @include font-size(160);
        }
    }

}
