.search-container {
    display: flex;
    position: relative;
    align-items: center;

    #searchInput {
        padding: 24px 40px 24px 24px;
        background-color: $search-input-bg;
        border-width: 1px;
        border-color: $search-input-border-color;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }

        &::placeholder {
            color: $color-text;
        }

        &:focus {
            border-color: $search-input-border-color--active;
        }
    }

    .search-svg {
        @include flex($alignItems: center, $justifyContent: center);
        position: absolute;
        top: 0;
        right: 0;
        width: 70px;
        height: 100%;
        background-color: $search-input-button-bg;
        cursor: pointer;

        svg {
            width: 15px;
            height: 15px;
            fill: $search-input-button-color;
            transition: all $duration;
        }

        &:hover, 
        &:focus {
            background-color: $search-input-button-bg--active;

            svg {
                fill: $search-input-button-color--active;
            }
        }
    }
}

.searchResult {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $color-white;
    border: 1px solid $color-light;
    border-top: none;

    li {
        position: relative;
        background: $color-white;
        border-bottom: 1px solid $color-light;

        &:last-child {
            border-bottom:0;
        }

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            font-weight: 600;
            color: $color-text;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-main;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-light;
                color: $color-text;

                &::after {
                    color: $color-text;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-text;
                }
            }
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}

body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 30px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $color-bg--body;
    overflow: hidden;
    transition: all 0.65s;

    &__container {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        overflow-y: auto;
    }

    .container {
        position: relative;
        height: 100%;
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
            padding-bottom: 50px;
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family--heading;
            font-size: $font-size--1;
            font-weight: $font-weight--heading;
            color: $color-text;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    &__shape {
        width: 450px;
        height: 150px;
        position: absolute;
        right: -60px;
        bottom: 130px;
        background-color: $color-third;   
        border-radius: 50px 0 0 50px;

        &::before,
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            right: 0;
            background-color: $color-third;
            transform: skewX(-7.5deg);
        }

        &::before {
            bottom: calc(100% + 30px);
        }

        &::after {
            top: calc(100% + 30px);
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        border-color: $color-gray;
        transition: all $duration;
        
        svg {
            width: 15px;
            height: 15px;
            fill: $color-text;
            transition: all $duration;
        }

        &:hover {
            background: $color-gray;

            svg {
                fill: $color-white;
            }
        }
    }
}


// //======================================================================================================
// // Search Page
// //======================================================================================================
.filter {
    .search-container {
        .searchInput:focus {
            border-color: $search-input-border-color--active !important;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }

    .search-popup__shape {
        width: 400px;
        height: 130px;
        right: -80px;
        bottom: 110px;  
    }

}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

    .search-popup__shape {
        width: 350px;
        height: 110px;
        right: -110px;
        bottom: 90px;  
    }

}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        @include font-size(40);
    }

    .search-popup__shape {
        width: 270px;
        height: 80px;
        right: -130px;
        bottom: 60px;
        
        &::before {
            bottom: calc(100% + 20px);
        }

        &::after {
            top: calc(100% + 20px);
        }
    }

}
