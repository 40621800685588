.nav-main {
    margin-top: 75px;

    .menu {
        width: 385px;
        display: flex;
        flex-direction: column;
        row-gap: 40px;

        &__item {
            &.menu-item-has-children {
                &.js-active-submenu,
                &:hover, 
                &:focus-within {
                    .menu__link,
                    .menu__link.active {
                        cursor: pointer;
                
                        &::before {
                            width: 50px !important;
                            background-color: $color-light;
                        }
                    }
                }

                &.js-active-submenu {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                    }
                }
            }

            &:nth-child(2) .menu__link::before {
                width: 35px;
            }

            &:nth-child(3) .menu__link::before {
                width: 25px;
            }

            &:nth-child(4) .menu__link::before {
                width: 15px;
            }
        }   
        
        &__link {
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 10px;
            font-family: $font-family--heading;
            font-size: toRem(25);
            line-height: 1.25em;
            font-weight: 500;
            letter-spacing: .05em;
            color: $color-white;
            text-transform: uppercase;

            &::before {
                content: '';
                width: 20px;
                height: 20px;
                background-color: $color-second;
                border-radius: 0 20px 20px 0;
                transition: $duration;
            }
        }
    }


    .submenu {
        width: 800px;
        height: 660px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 995;
        background: #12223E;
        border-radius: 200px 0 0;
        padding-bottom: 30px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: $duration;

        &::after {
            content: '';
            width: 3000px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 100%;
            background-color: #12223E;
        }

        &__nav {
            width: 720px;
            column-count: 2;
            column-gap: 30px;
            padding: 0;
            margin: 0;
        }

        &__item {
            width: 100%;
            display: inline-flex;
            padding: 0;
        }

        &__link {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 5px;
            font-weight: 700;
            color: $color-white;
            padding: 10px 0;
            transition: $duration;

            svg {
                width: 25px;
                height: 25px;
                flex-shrink: 0;
                fill: $color-white;
                transform: rotate(90deg);
                transition: $duration;
            }

            &:hover,
            &:focus {
                color: $color-second;

                svg {
                    fill: $color-second;
                }
            }
        }
    }
}





//=================================================================================================
// RESPONSIVE
//=================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .nav-main {
            margin-top: 62.5px;
        
            .menu {
                width: 330px;
                row-gap: 42.5px;

                &__item {
                    &:nth-child(2) .menu__link::before {
                        width: 30px;
                    }
        
                    &:nth-child(3) .menu__link::before {
                        width: 20px;
                    }
        
                    &:nth-child(4) .menu__link::before {
                        width: 10px;
                    }
                }   
                
                &__link {
                    font-size: toRem(22);
        
                    &::before {
                        width: 15px;
                        height: 15px;
                    }
        
                    &:hover,
                    &:focus,
                    &.active {
                        &::before {
                            width: 40px !important;
                        }
                    }
                }
            }
        
        
            .submenu {
                width: 630px;
                height: 610px;
                border-radius: 160px 0 0 0;
                padding-bottom: 20px;
        
        
                &__nav {
                    width: 580px;
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .nav-main {
            display: none;
        }
    }