.cover {
    margin-bottom: 50px;

    &__container {
        background-color: $color-dark;

        > .container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &__image {
        width: 785px;
        height: 660px;
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(360deg, rgba(0, 16, 45, 0.5) 0%, rgba(0, 16, 45, 0.075) 32.31%, rgba(0, 16, 45, 0.075) 49.48%, rgba(0, 16, 45, 0.075) 100%);
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__shape {
        &--1200,
        &--960,
        &--640 {
            display: none;
        }
    }

    &__image-wrapper {
        &--1200,
        &--960,
        &--640 {
            display: none;
        }
    }

    &__content {
        width: 100%;
        position: relative;

        &::after {
            content: url($urlShort + 'css-icons/forme_intro.svg');
            width: 100px;
            position: absolute;
            top: -20px;
            right: 0;
        }

        > .container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__title {
        margin: 50px 0 0;
    }

    &__text {
        font-size: $font-size--text-medium;
        line-height: 1.25em;
        font-weight: 700;
        margin-top: 15px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .cover { 
            margin-bottom: 40px;       

            &__image {
                width: 620px;
                height: 610px;
            }

            &__shape {
                display: none;

                &--1200 {
                    display: block;
                }
            }

            &__image-wrapper {
                display: none;

                &--1200 {
                    display: block;
                }
            }
        
            &__content::after {
                width: 90px;
                top: -15px;
            }
        
            &__title {
                margin: 40px 0 0;
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .cover {    
            margin-bottom: 30px;    

            &__image {
                width: 100%;
                height: 580px;
            }

            &__shape {
                &--1200 {
                    display: none;
                }

                &--960 {
                    display: block;
                }
            }

            &__image-wrapper {
                transform: translateY(75px);

                &--1200 {
                    display: none;
                }

                &--960 {
                    display: block;
                }
            }
        
            &__content::after {
                width: 80px;
                top: -10px;
            }
        
            &__title {
                font-size: toRem(42);
                line-height: 1.2em;
                margin: 30px 0 0;
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .cover {  
            margin-bottom: 20px;    

            &__image {
                aspect-ratio: 1;
                height: auto;
                max-height: 555px;
            }

            &__shape {
                &--960 {
                    display: none;
                }

                &--640 {
                    display: block;
                }
            }

            &__image-wrapper {
                transform: translateY(63px);

                &--960 {
                    display: none;
                }

                &--640 {
                    display: block;
                }
            }
        
            &__content::after {
                display: none;
            }
        
            &__title {
                font-size: $font-size--2;
                margin: 20px 0 0;
            }
        }
    }