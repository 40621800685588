.ar {
    &-button {
        width: 120px;
        height: 80px;
        position: fixed;
        top: 130px;
        right: 0;
        z-index: 990;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        row-gap: 10px;
        background-color: $color-main;
        border: none;
        border-radius: 100px 0 0 100px;
        padding: 0 10px;
        margin: 0;
        transition: $duration;

        &:hover,
        &:focus {
            background-color: $color-second;

            .ar-button {
                &__icon {
                    fill: $color-text;
                }

                &__text {
                    color: $color-text;
                }
            }
        }

        &--responsive {
            display: none;
        }

        &__icon {
            width: 6px;
            height: 10px;
            fill: $color-white;
            transition: $duration;
        }

        &__text {
            font-family: $font-family--heading;
            font-size: $font-size--text-small;
            line-height: 1.1em;
            font-weight: 500;
            letter-spacing: .05em;
            color: $color-white;
            text-transform: uppercase;
            text-align: right;
            transition: $duration;
        }
    }

    &-container {
        width: 720px;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        transform: translateX(100%);
        z-index: 995;
        display: flex;
        flex-direction: column;
        row-gap: 60px;
        background-color: $color-main;
        padding: 60px 40px 30px;
        overflow-y: auto;
        transition: $duration;

        &.active {
            transform: translateX(0);
        }

        &__header {
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            position: relative;
            column-gap: 20px;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--text-medium;
            line-height: 1em;
            font-weight: 300;
            letter-spacing: .25em;
            color: $color-white;
            text-transform: uppercase;
            text-align: center;
            margin: 0;

            &::before {
                display: none;
            }
        }

        &__close {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border-color: $color-white;
            padding: 0;
            margin: 0;

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-text;
                }
            }

            svg {
                width: 12px;
                height: 12px;
                fill: $color-white;
                transform: rotate(180deg);
                transition: $duration;
            }
        }

        &__items {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
    }

    &-item {
        width: 310px;
        height: 100px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $color-white;
        padding: 0 30px 0 25px;

        &::before,
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            background-color: $color-white;
            transition: $duration;
        }

        &::before {
            top: -1px;
            right: 0;
        }

        &::after {
            right: unset;
            left: 0;
            bottom: -1px;
        }

        &:hover,
        &:focus {
            border: 1px solid $color-second;
            transition: .3s;

            &::before,
            &::after {
                width: 0;
                transition: .3s .3s ease-out;
            }
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--5;
            line-height: 1.4em;
            font-weight: 600;
            letter-spacing: .05em;
            color: $color-white;
            margin: 0;
            transition: $duration;

            &::before {
                display: none;
            }
        }

        &__icon {
            width: 30px;
            height: 30px;
            fill: $color-white;
            transition: $duration;
        }
    }
}





//======================================================================================================
// RESPONSIVE
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .ar-button {
            width: 100px;
            height: 70px;
            padding: 0 5px;
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .ar {
            &-button {
                height: 85px;
                position: relative;
                top: unset;
                right: unset;
                display: none;
                align-items: center;
                border-radius: 60px 60px 0 0;
                margin-bottom: 15px;

                &--responsive {
                    display: flex;
                    cursor: pointer;
                }

                &__icon {
                    transform: rotate(90deg);
                }

                &__text {
                    text-align: center;
                }
            }

            &-container {
                width: 100vw;
                height: auto;
                max-height: 100%;
                row-gap: 50px;
                top: unset;
                bottom: 0;
                align-items: center;
                padding: 40px 0 60px;
                transform: translateY(100%);

                &.active {
                    transform: translateY(0);
                }

                &__header {
                    width: 640px;
                }

                &__close {
                    right: 0;

                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &__items {
                    justify-content: center;
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .ar {
            &-button {
                width: 85px;
            }

            &-container {
                row-gap: 40px;
                padding-top: 20px;

                &__header {
                    width: 310px;
                    justify-content: space-between;
                }

                &__close {
                    position: relative;
                    top: unset;
                    right: unset;
                    transform: unset;
                }

                &__items {
                    width: 100%;
                    justify-content: center;
                }
            }
        }
    }