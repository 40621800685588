.footer {
    background-color: $color-dark;
    padding-top: 80px;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__titles,
    &__links {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__titles {
        column-gap: 30px;
    }

    &__links {
        column-gap: 100px;
        margin-top: 40px;
    }

    &__logo {
        width: 300px;
        display: flex;
        justify-content: center;

        svg {
            width: 215px;
            height: 125px;
        }
    }

    &__mairie,
    &__horaires {
        width: 370px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__mairie {
        margin: 0 0 0 auto;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--4;
        line-height: 1.3em;
        font-weight: 700;
        color: $color-white;
        margin: 0;
    }

    &__text {
        color: $color-white;
        margin-top: 12.5px;
    }

    &__tel,
    &__contact,
    &__label {
        width: 300px;
    }

    &-menu {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;

        &__item {
            position: relative;

            &::after {
                content: '';
                width: 20px;
                height: 10px;
                position: absolute;
                top: 50%;
                right: -62.5px;
                transform: translateY(-50%);
                border: 1px solid $color-light;
                border-radius: 20px;
            }

            &:last-child::after {
                display: none;
            }
        }

        &__link {
            color: $color-light;
            padding: 5px 0;

            &:hover,
            &:focus {
                color: $color-white;
            }
        }
    }

    &__background {
        width: 100%;
        display: flex;
        margin-top: 30px;
        
        svg {
            width: 100%;
            max-height: 90px;
        }
    }
}





//======================================================================================================
// RESPONSIVE 
//======================================================================================================
    // 1200
    @media screen and (max-width: $large) {
        .footer {        
            &__titles {
                column-gap: 35px;
            }
        
            &__links {
                column-gap: 45px;
            }
        
            &__logo {
                width: 260px;
        
                svg {
                    width: 172px;
                    height: 100px;
                }
            }
        
            &__mairie {
                width: 270px;
            }

            &__horaires {
                width: 280px;
                margin-right: 50px;
            }
        
            &__text {
                font-size: $font-size--text-small;
            }
        
            &__tel,
            &__contact,
            &__label {
                width: 260px;
            }
        
            &-menu {
                margin-top: 57.5px;
        
                &__item::after {
                    right: -47.5px;
                }

                &__link {
                    font-size: $font-size--text-small;
                }
            }
        }
    }



    // 960
    @media screen and (max-width: $medium) {
        .footer {    
            padding-bottom: 70px;
                
            &__titles {
                flex-wrap: wrap;
                row-gap: 60px;
                column-gap: 0;
            }
        
            &__links {
                justify-content: center;
                flex-wrap: wrap;
                row-gap: 22.5px;
                column-gap: 70px;
                margin-top: 30px;
            }

            &__logo {
                width: 100%;
            }

            &__mairie,
            &__horaires {
                align-items: center;
            }
        
            &__mairie {
                width: 300px;
                margin: 0;
            }

            &__horaires {
                width: 280px;
                margin: 0 10px 0 auto;
            }
        
            &__title,
            &__text {
                text-align: center;
            }
        
            &__tel,
            &__contact,
            &__label {
                width: 250px;
            }
        
            &-menu {
                justify-content: center;
                flex-wrap: wrap;
                column-gap: 70px;
                row-gap: 15px;
                margin-top: 57.5px;
        
                &__item {
                    &::after {
                        right: -45px;
                    }

                    &:nth-child(3)::after {
                        display: none;
                    }
                }
            }
        }
    }



    // 640
    @media screen and (max-width: $small) {
        .footer {      
            padding-top: 57.5px;
            
            &__titles {
                row-gap: 30px;
            }
        
            &__links {
                flex-direction: column;
                align-items: center;
                row-gap: 20px;
            }

            &__mairie,
            &__horaires {
                width: 100%;
            }
        
            &__horaires {
                margin: 0;
            }
        
            &__tel,
            &__contact,
            &__label {
                width: 240px;
                min-height: 60px;
            }
        
            &-menu {
                width: 300px;
                column-gap: 80px;
                margin: 57.5px auto 0;
        
                &__item {
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(4) {
                        &::after {
                            display: none;
                        }
                    }

                    &:nth-child(3) {
                        &::after {
                            display: block;
                        }
                    }
                }
            }
        }
    }