.breadcrumb {
    position: relative;
    z-index: 1;
    background-color: $color-bg--card;
    padding: 17.5px 0;

    ul  {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 5px;
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 5px;

        &::after {
            content: url($urlShort + 'home-icons/ic_chevron_ariane.svg');
            width: 20px;
            height: 20px;
            transform: rotate(90deg);
            transition: $duration;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        a,
        span {
            display: flex;
            align-items: center;
            column-gap: 4px;
            padding: 2.5px 0;
            transition: $duration;
        }

        a {
            &:hover,
            &:focus {
                color: $color-main;
            }
        }
    }
}